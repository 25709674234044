<template>
  <div>
    <div class="all_container">
      <div class="container">
        <!-- 首页切换 -->
        <div class="changeClassify">
          <div class="title" @click="gohome">{{ $t("other.homePage") }}</div>
          <div>-</div>
          <div>{{ lang == "zh" ? zh_name : bnm_name }}</div>
        </div>
        <div class="carousel">
          <!-- 中间的轮播图 -->
          <div class="carousel_right">
            <el-carousel trigger="click" height="450px" arrow="always">
              <el-carousel-item
                v-for="(item, index) in bannerArr1"
                :key="index"
              >
                <img
                  :src="item.thumb"
                  class="big_img"
                  @click="bannerArrChange(item)"
                />
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <!-- 信息分类区 -->
        <div class="main_body">
          <!-- 信息分类 -->
          <div class="info" style="padding: 88px 0 0 0">
            <div class="title">
              <div>{{ lang == "zh" ? zh_name : bnm_name }}</div>
              <div class="border"></div>
            </div>
            <div class="classify">
              <div class="top">
                <div
                  class="top_box"
                  v-for="(item, index) in classify"
                  :key="index"
                  @click="goClassify(item)"
                >
                  <div class="img">
                    <img :src="item.thumb" alt="" />
                  </div>
                  <div class="top_box_border">
                    <div class="title">
                      {{ lang == "zh" ? item.name : item.bnm_name }}
                    </div>
                    <!-- <div class="title">{{ item.name }}</div> -->
                  </div>
                </div>
              </div>
              <!-- <div class="bottom">
                              </div> -->
            </div>
          </div>

          <!-- 新楼盘 -->
          <div class="info" style="padding-top: 88px">
            <div class="title">
              <div>{{ $t("home.newLouPan") }}</div>
              <div class="border"></div>
            </div>
            <div class="loupan">
              <div
                class="loupan_box"
                v-for="(item, index) in new_house"
                :key="index"
                @click="goNew_house(item.id)"
              >
                <div class="loupan_box_top">
                  <img :src="item.thumb[0]" alt="" class="top_img" />
                </div>

                <div class="loupan_box_bottom">
                  <div class="top_box">
                    <div class="top_box_title">
                      {{ item.title }}
                    </div>
                    <img :src="item.company_logo" alt="" class="top_box_img" v-if="item.company_logo"/>
                  </div>

                  <div class="center_box">${{ item.price }}</div>
                  <div class="bottom_box">
                    <div class="bottom_box_left">
                      <img
                        src="../../../assets/new_home_icons/address_icon.png"
                        alt=""
                      />
                      <div class="name">
                        {{ item.address }}
                      </div>
                    </div>
                    <div class="bottom_box_right">
                      <img
                        src="../../../assets/new_home_icons/photo_icon.png"
                        alt=""
                      />
                      <div class="name">1/{{ item.thumb.length }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- 房地产 -->
          <div class="info" style="padding-top: 88px">
            <div class="title">
              <div>{{ $t("home.realEstate") }}</div>
              <div class="border"></div>
            </div>
            <div class="release">
              <div
                class="release_box"
                v-for="(item, index) in house_hot"
                :key="index"
                @click="goDetails(item.id)"
              >
                <div class="release_box_top">
                  <img :src="item.thumb[0]" alt="" />
                </div>
                <div class="release_box_bootom">
                  <div class="title">{{ item.title }}</div>
                  <div class="address">
                    <div class="address_left">${{ item.price }}</div>
                    <div class="address_right">
                      <img
                        src="@/assets/new_home_icons/address_icon.png"
                        alt=""
                      />
                      <div class="name">{{ item.address }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- 最新上传 -->
          <div class="info" style="padding-top: 88px">
            <div class="title">
              <div>{{ $t("home.newList") }}</div>
              <div class="border"></div>
            </div>
            <div class="release">
              <div
                class="release_box"
                v-for="(item, index) in new_list"
                :key="index"
                @click="goDetails(item.id)"
              >
                <div class="release_box_top">
                  <img :src="item.thumb[0]" alt="" />
                </div>
                <div class="release_box_bootom">
                  <div class="title">{{ item.title }}</div>
                  <div class="address">
                    <div class="address_left">${{ item.price }}</div>
                    <div class="address_right">
                      <img
                        src="@/assets/new_home_icons/address_icon.png"
                        alt=""
                      />
                      <div class="name">{{ item.address }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="info" style="padding: 88px 0 0 0; padding-top: 88px">
            <div class="title">
              <div>{{ $t("home.realEstateCompany") }}</div>
              <div class="border"></div>
            </div>
            <div class="company">
              <div class="company_box">
                <img
                  :src="item.logo"
                  alt=""
                  v-for="(item, index) in company_list"
                  :key="index"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <img
      src="../../../assets/loading.gif"
      class="loadingImg"
      v-if="loadingValue == true"
    />
  </div>
</template>
  
  <script>
import houseItem from "@/components/houseItem.vue";

export default {
  components: {
    houseItem,
  },
  data() {
    return {
      classifyStatus: "",
      recommendList: [], //热门推荐列表
      likeList: [], //喜欢列表

      total: 8, //总条数
      currentPage: 1, //当前页数
      pageSize: 16, //每页的条数
      pageSizes: [16],

      bannerArr1: [], //轮播图1
      bannerArr2: [],
      bannerArr3: [],
      bannerArr4: [],
      bannerArr5: [],
      bannerArr6: [],
      bannerArr7: [],

      classify: [],

      loupanList: [
        {
          img: require("@/assets/new_home_icons/loupan.png"),
          title:
            "万科大都会开盘优惠不容错过万科大都会开盘优惠不容错过开盘优惠不容错过",
          money: "$1.000.00",
          icon: require("@/assets/new_home_icons/gongsi.png"),
        },
      ],

      lang: "",
      vip: "",
      loadingValue: false,
      pid: "",

      new_house: [], //新楼盘
      house_hot: [], //商业中心
      new_list: [], //最新上传
      company_list: [], //房地产公司
    };
  },
  created() {
    this.pid = this.$route.query.pid;
    this.zh_name = this.$route.query.zh_name;
    this.bnm_name = this.$route.query.bnm_name;
    // console.log('打开')
    // console.log('关闭')
    this.lang = localStorage.getItem("lang");
    this.getSearchInfo();

    this.getCompany();
    // this.getRecommend();
    // this.getAllLike();
    this.getAds(this.pid);

    // this.getCateList()
    this.getChildrenCate();

    // 实现监听localstorage中某个键对应的值的变化
    //根据自己需要来监听对应的key
    window.addEventListener("setItemEvent", (e) => {
      //e.key : 是值发生变化的key
      //例如 e.key==="token";
      //e.newValue : 是可以对应的新值
      if (e.key === "lang") {
        this.lang = e.newValue;
      }
    });

    this.getUserInfo();
  },

  mounted() {},
  methods: {
    gohome() {
      this.classifyStatus = 3;
      this.$emit("chaangeClassifyStatus", this.classifyStatus);
    },
    // 跳转到房地产分类页
    goClassify(item) {
      console.log(item.id);
      if (item.id == 159) {
        this.$router.push({
          // 新楼盘分类
          path: "/realtyClassify",
          query: {
            pid: item.id,
            zh_name: item.zh_name,
            bnm_name: item.bnm_name,
          },
        });
      } else {
        this.$router.push({
          path: `/classify_leve2`,
          query: {
            classify_id: item.id,
            zh_name: item.name,
            bnm_name: item.bnm_name,
            father_zh_name: this.zh_name,
            father_bnm_name: this.bnm_name,
          },
        });
      }
    },
    // 获取分类信息
    getCateList() {
      this.$http.getCate().then((res) => {
        if (res.code == 1) {
          this.classify = res.data;
          console.log(this.classify, 11111);
        } else {
          this.$message.info(res.msg);
        }
      });
    },

    // 获取二级分类
    getChildrenCate() {
      this.$http
        .getChildrenCate({
          pid: this.pid,
          type: 2,
        })
        .then((res) => {
          if (res.code == 1) {
            console.log(res.data, "二级分类");
            this.classify = res.data;
          }
        });
    },

    // 获取本页面详情
    getSearchInfo() {
      this.$http
        .getSearchInfo({
          index_cate_id: this.pid,
          order: "desc",
        })
        .then((res) => {
          if (res.code == 1) {
            console.log(res, "获取本页面详情");
            this.new_house = res.data.new_house; //新楼盘
            this.house_hot = res.data.house_hot; //房地产
            this.new_list = res.data.new_list; //最新上传
          } else {
            this.$message.error(res.msg);
          }
        });
    },

    // 获取经销商 company
    getCompany() {
      this.$http
        .companyList({
          type: 0,
        })
        .then((res) => {
          if (res.code == 1) {
            this.company_list = res.data;
          } else {
            this.$message.error(res.msg);
          }
        });
    },

    // 轮播图切换事件
    bannerArrChange(item) {
      console.log(item);
      let ads_type = item.ads_type;
      let bind_information_id = item.bind_information_id;
      let href = item.href;

      if (bind_information_id != 0) {
        if (item.url == 1) {
          this.$router.push({
            path: "/particulars",
            query: {
              id: bind_information_id,
              url: item.url,
            },
          });
        } else if (item.url == 2) {
          this.$router.push({
            path: "/particulars",
            query: {
              id: bind_information_id,
              url: item.url,
            },
          });
        } else {
          this.$router.push({
            // 普通页
            path: "/details",
            query: {
              id: item.bind_information_id,
            },
          });
        }
      } else {
        window.open('https://' +  href);
      }
    },
    // 获取用户信息
    getUserInfo() {
      this.$http.userInfo().then((res) => {
        if (res.code == 1) {
          this.vip = res.data.vip;
        } else if (res.code == -201) {
        } else {
          this.$message.info(res.msg);
        }
      });
    },
    // 获取轮播图
    getAds(id) {
      console.log(id, "一级分类id");
      this.$http
        .getAds({
          typeid: 12,
          category_id: id,
        })
        .then((res) => {
          if (res.code == 1) {
            console.log(res);
            this.bannerArr1 = res.data;
          } else {
            this.$message.info(res.msg);
          }
        });
    },
    // 	去热门推荐
    toHotRecommand() {
      // let types = 1;
      this.$router.push({
        path: "/hotRecommand",
        // query:{
        //     pages:types,
        // }
      });
      // window.open('/hotRecommand')
      // let routeUrl=this.$router.resolve({
      // 	path: '/home/hotRecommand'
      // })
      // window.open(routeUrl.href,'_blank')
    },
    // 获取热门推荐
    getRecommend() {
      this.$http
        .getRecommend({
          pageNo: 1,
          pageSize: 24,
          is_index: 1,
          type: 1,
        })
        .then((res) => {
          if (res.code == 1) {
            this.recommendList = res.data.data;
            this.$forceUpdate();
          } else {
            this.$message.error(res.data.msg);
          }
          // console.log(res);
        })
        .catch((v) => {
          console.log(v);
        });
    },
    // 获取所有喜欢的
    getAllLike() {
      let data = {
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        is_index: 1,
        type: 2,
      };
      this.loadingValue = true;
      this.$http
        .getRecommend(data)
        .then((res) => {
          this.loadingValue = false;
          window.scrollTo(0, 0);
          console.log(res);
          if (res.code == 1) {
            this.total = res.data.total;
            this.likeList = res.data.data;
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch(() => {
          this.loadingValue = false;
        });
    },

    currentChangeClick(currentPage) {
      this.currentPage = currentPage;
      this.getAllLike();
    },
    sizeChangeClick(pageSize) {
      this.pageSize = pageSize;
      this.getAllLike();
    },

    // 去新楼盘详情
    goNew_house(id) {
      this.$router.push({
        path: "/particulars",
        query: {
          id: id,
        },
      });
    },
    // 去普通详情
    goDetails(id) {
      this.$router.push({
        path: "/details",
        query: {
          id: id,
        },
      });
    },
  },
};
</script>
  
  <style lang="less" scoped="scoped">
.big_img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.all_container {
  background: #f6f7f9;
}

.container {
  width: 100%;
  // height: 446px;
  margin: 0 auto;
  padding-bottom: 64px;
}

.changeClassify {
  display: flex;
  // justify-content: space-between;
  // width: 84px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin: 32px 0 0 32px;

  .title {
    color: #f39800;
  }
}

.carousel {
  margin-top: 36px;
  display: flex;

  .carousel_left {
    width: 232px;
  }

  .carousel_right {
    width: 100%;
    margin: 0 auto;
  }
}

.main_body {
  // 信息分类
  .info {
    // padding-top: 88px;
    background: #f6f7f9;
    padding: 0 36px;

    .title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 28px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;

      .border {
        width: 40px;
        height: 4px;
        background: rgba(243, 152, 0);
        border-radius: 2px;
        margin-top: 8px;
      }
    }

    .classify {
      position: relative;
      background-color: #fff;
      // height: 460px;
      width: 100%;
      box-sizing: border-box;

      .top {
        margin-top: 32px;
        padding: 71px 32px 0;
        // display: grid;
        // justify-content: center;
        // grid-template-columns: 16% 16% 16% 16% 16% 16%;

        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .top_box {
          width: 16%;
          position: relative;
          margin-bottom: 48px;
          margin-right: 10px;

          .img {
            position: absolute;
            top: -30%;
            left: 50%;
            transform: translatex(-60%);
            width: 70px;
            height: 70px;

            // background-color: pink;
            img {
              width: 100%;
              height: 100%;
            }
          }

          .top_box_border {
            // width: 280px;
            height: 105px;
            border-radius: 5px;
            // background-color: pink;
            border: 1px solid #ff8100;
            border-bottom: 6px solid #ff8100;

            .title {
              margin-top: 50px;
              font-size: 22px;
              text-align: center;
            }
          }
        }
      }

      .bottom {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 48px;
        background-image: url("../../../assets/new_home_icons/bottom.png");
        background-repeat: no-repeat;
        background-size: cover;
      }
    }

    .loupan {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .loupan_box {
        width: 580px;
        // height: 472px;
        background: #fff;
        opacity: 1;
        margin-top: 56px;

        .loupan_box_top {
          width: 580px;
          height: 320px;

          .top_img {
            width: 100%;
            height: 100%;
          }
        }

        .loupan_box_bottom {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          min-height: 152px;
          padding: 0 16px;

          .top_box {
            display: flex;
            justify-content: space-between;

            .top_box_title {
              width: 440px;
              font-size: 18px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #333333;
              text-align: left;
            }

            .top_box_img {
              width: 150px;
              height: 150px;
            }
          }

          .center_box {
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #f39800;
            text-align: left;
          }

          .bottom_box {
            display: flex;
            justify-content: space-between;

            .bottom_box_left {
              display: flex;
              justify-content: left;
              align-items: center;

              img {
                width: 14px;
                height: 14px;
              }

              .name {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #888888;
              }
            }

            .bottom_box_right {
              display: flex;
              justify-content: right;
              align-items: center;

              img {
                width: 14px;
                height: 14px;
                margin-right: 8px;
              }

              .name {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #888888;
              }
            }
          }
        }
      }
    }

    .release {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 56px;

      .release_box {
        width: 290px;
        height: 400px;
        background: #fff;
        margin-bottom: 24px;

        .release_box_top {
          height: 260px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .release_box_bootom {
          height: 110px;
          padding: 0 16px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: flex-start;


          .title {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
            text-align: left;
          }

          .address {
            display: block;

            .address_left {
              font-size: 18px;
              font-weight: bold;
              color: #f39800;
              margin-right: 10px;
              margin-bottom: 10px;
              text-align: left;
            }

            .address_right {
              display: flex;
              justify-content: left;
              align-items: center;

              img {
                width: 14px;
                height: 14px;
                margin-right: 5px;
              }

              .name {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #888888;
                word-wrap: break-word;
                text-align: left;
              }
            }
          }
        }
      }
    }

    .company {
      margin-top: 30px;
      width: 100%;

      .company_box {
        padding: 56px 54px;
        // height: 328px;

        display: flex;
        flex-wrap: wrap;
        background: #fff;
        justify-content: left;

        img {
          width: 180px;
          height: 96px;
          margin-right: 20px;
          margin-bottom: 24px;
        }

        img:nth-child(9n) {
          margin-right: 0 !important;
        }
      }
    }
  }
}

.hot_recommand {
  box-sizing: border-box;
  display: flex;
  margin-top: 16px;

  .hot_recommand_left {
    width: 232px;

    .two {
      margin-top: 16px;
    }
  }

  .hot_recommand_right {
    width: 968px;
    padding-left: 10px;

    .hot_img {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      margin-bottom: 20px;

      img {
        width: 205px;
        height: 52px;
      }
    }

    .all_house {
      display: flex;
      flex-wrap: wrap;
    }

    .check_more {
      margin-top: 24px;

      &:hover {
        cursor: pointer;
      }

      img {
        width: 120px;
        height: 36px;
      }
    }
  }
}

.vip {
  display: flex;
  margin-top: 40px;

  .vip_left {
    width: 232px;
  }

  .vip_right {
    width: 968px;

    .vip_img {
      img {
        width: 928px;
        height: 112px;
      }
    }

    .vip_carousel {
      margin-top: 32px;
      display: flex;
      justify-content: space-between;

      .vip_carousel_left {
        width: 474px;
      }

      .vip_carousel_right {
        width: 474px;
      }
    }

    .vip_big_carousel {
      margin-top: 10px;
      width: 958px;
    }
  }
}

.like {
  .like_img {
    margin: 40px 0 20px;

    img {
      width: 316px;
      height: 44px;
    }
  }

  .all_house {
    display: flex;
    flex-wrap: wrap;
  }
}

.carousel_left {
  width: 232px;

  /deep/ .el-carousel__arrow {
    top: 72%;
  }

  /deep/ .el-carousel__arrow--left,
  /deep/ .el-carousel__arrow--right {
    width: 24px;
    height: 24px;
  }
}

.carousel_left2 {
  width: 232px;

  /deep/ .el-carousel__arrow {
  }

  /deep/ .el-carousel__arrow--left,
  /deep/ .el-carousel__arrow--right {
    width: 24px;
    height: 24px;
  }
}

/deep/.el-carousel__indicators--horizontal {
  .el-carousel__indicator--horizontal button {
    width: 8px;
    height: 8px;
    background: #ffffff;
    border-radius: 50%;
    opacity: 0.5;
  }

  .el-carousel__indicator--horizontal.is-active button {
    width: 24px;
    height: 8px;
    background: #ffffff;
    opacity: 0.5;
    border-radius: 10px;
  }
}

.pagination {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
}

// 新增的样式
.loading {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0rpx;
  z-index: 11;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingImg {
  width: 100rpx;
  height: 100rpx;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 11;
}
</style>
  